<template>
  <div id="goods_detail">
    <div class="goods_info_center wrap clearfix">
      <div class="goods_info_top_left fl">
        <div class="goods_image_item">
          <pic-zoom url="../07.jpg" :scale="3" />
        </div>
        <div class="pic_zoom_list">
          <div class="pic_zoom_list_left fl">
            <i class="el-icon-arrow-left"></i>
          </div>
          <ul>
            <li class="fl border_color">
              <img src="@/assets/img/08.jpg" alt="" />
            </li>
          </ul>
          <div class="pic_zoom_list_right fr">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="goods_info_top_right fl">
        <div class="goods_name">洗衣机槽泡腾片一盒 12颗装可用半年</div>
        <div class="goods_subname">电商价格19.8</div>
        <div class="attr fl clearfix">
          <div class="price">
            <span>价格：</span>
            <span>
              <font>￥</font>
              2.30
            </span>
          </div>
          <div class="wei">
            <span>重量：</span>
            <span>0.15 kg/件</span>
          </div>
          <div class="stock">
            <span>库存：</span>
            <span>8321件</span>
          </div>
          <div class="num">
            <span>数量：</span>
            <div class="num_handle">
              <div class="goods_info_num_jian fl">
                <i class="el-icon-minus"></i>
              </div>
              <div class="goods_info_num_input fl">
                <input type="text" />
              </div>
              <div class="goods_info_num_jia fl">
                <i class="el-icon-plus"></i>
              </div>
              <div class="goods_info_num_stock fl">&nbsp;&nbsp;件</div>
            </div>
          </div>
          <div class="storage">
            <span>仓储：</span>
            <div class="storage_handle">
              <div class="item red">
                义乌仓储
                <img src="@/assets/img/09.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="buy_btn">
          <div class="btn fl">立即购买</div>
        </div>
      </div>
    </div>
    <div class="goods_info_content wrap">
      <div class="title">商品详情</div>
      <div class="content">
        <p>
          <img src="@/assets/img/10.jpg" alt="" />
          <img src="@/assets/img/11.jpg" alt="" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PicZoom from "vue-piczoom";
export default {
  name: "",
  components: {
    PicZoom,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#goods_detail {
  .goods_info_top_left {
    width: 402px;
    border: 1px solid #efefef;
    margin-right: 28px;
    box-sizing: border-box;
  }
  .goods_info_top_right {
    .goods_name {
      font-size: 18px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;
      margin-bottom: 2px;
    }
    .goods_subname {
      font-size: 14px;
      line-height: 36px;
      color: #f64f6f;
      font-weight: 400;
      margin-bottom: 15px;
    }
    .attr {
      margin-right: 35px;
      font-size: 14px;
      color: #999;
      div span {
        &:first-child {
          margin-right: 30px;
        }
      }
      .price,
      .wei,
      .stock,
      .num {
        height: 45px;
        display: flex;
        align-items: center;
      }
      .price {
        span {
          &:first-child {
            margin-right: 25px;
          }
          &:last-child {
            color: #ff0036;
            font-size: 24px;
            font-weight: bolder;
            font-family: Arial;
            text-decoration: none;
            -webkit-font-smoothing: antialiased;
            vertical-align: middle;
          }
        }
      }
      .wei {
        span:last-child {
          color: #333;
          font-size: 14px;
          line-height: 35px;
        }
      }
      .stock {
        span:last-child {
          color: #666;
          font-size: 14px;
          line-height: 35px;
        }
      }
      .num {
        height: 45px;
        display: flex;
        align-items: center;
        span:first-child {
          margin-right: 25px;
        }
        input {
          height: 40px;
          border: 1px solid #efefef;
          outline: none;
          width: 80px;
          margin-right: 5px;
          box-sizing: border-box;
          padding: 0 8px;
          color: #333;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
        }
        .goods_info_num_jian,
        .goods_info_num_jia {
          border-radius: 3px;
          width: 40px;
          height: 40px;
          line-height: 25px;
          text-align: center;
          margin-right: 5px;
          color: #c0c4cc;
          background: #efefef;
          i {
            line-height: 40px;
            font-size: 16px;
            font-weight: bold;
          }
        }
        .goods_info_num_stock {
          line-height: 40px;
          color: #333;
        }
      }
      .storage {
        height: 45px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        span:first-child {
          margin-right: 23px;
        }
        .item {
          border: 1px solid #ccc;
          position: relative;
          color: #666;
          padding: 2px 10px 2px 5px;
          line-height: 28px;
          min-width: 80px;
          text-align: center;
          float: left;
          margin: 2px;
          border-radius: 5px;
          &.red {
            border-color: #ea242f;
            color: #ea242f;
          }
          img {
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            height: 22px;
          }
        }
      }
    }
    .buy_btn {
      margin-top: 30px;
      line-height: 40px;
      color: #999;
      font-size: 14px;
      .btn {
        line-height: 40px;
        background: #ff8f22;
        color: #fff;
        cursor: pointer;
        min-width: 152px;
        font-size: 18px;
        margin-right: 15px;
        text-align: center;
      }
    }
  }
  .goods_image_item {
    width: 400px;
    height: 400px;
    display: block;
    border-bottom: 1px solid #efefef;
  }
  .pic_zoom_list {
    .pic_zoom_list_left {
      font-size: 24px;
      line-height: 80px;
      color: #666;
      margin-right: 10px;
      margin-left: 10px;
      &:hover {
        cursor: pointer;
        color: #ff464e;
      }
    }
    .pic_zoom_list_right {
      font-size: 24px;
      line-height: 80px;
      color: #666;
      margin-right: 10px;
      margin-left: 10px;
      &:hover {
        cursor: pointer;
        color: #ff464e;
      }
    }
    ul {
      li {
        margin: 10px 10px 10px 0;
        border: 1px solid #efefef;
        img {
          width: 60px;
          height: 60px;
        }
        &.border_color {
          border-color: #ea242f;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .goods_info_content {
    border: 1px solid #efefef;
    margin-top: 30px;
    margin-bottom: 30px;
    .title {
      line-height: 40px;
      background: #f0f0f0;
      text-align: center;
      width: 100%;
      color: #666;
    }
    .content {
      color: #666;
      padding: 20px;
    }
    p {
      text-align: center;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
