var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"goods_detail"}},[_c('div',{staticClass:"goods_info_center wrap clearfix"},[_c('div',{staticClass:"goods_info_top_left fl"},[_c('div',{staticClass:"goods_image_item"},[_c('pic-zoom',{attrs:{"url":"../07.jpg","scale":3}})],1),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"goods_info_top_right fl"},[_c('div',{staticClass:"goods_name"},[_vm._v("洗衣机槽泡腾片一盒 12颗装可用半年")]),_vm._v(" "),_c('div',{staticClass:"goods_subname"},[_vm._v("电商价格19.8")]),_vm._v(" "),_c('div',{staticClass:"attr fl clearfix"},[_c('div',{staticClass:"price"},[_c('span',[_vm._v("价格：")]),_vm._v(" "),_c('span',[_c('font',[_vm._v("￥")]),_vm._v("\n            2.30\n          ")],1)]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4)]),_vm._v(" "),_c('div',{staticClass:"clearfix"}),_vm._v(" "),_vm._m(5)])]),_vm._v(" "),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic_zoom_list"},[_c('div',{staticClass:"pic_zoom_list_left fl"},[_c('i',{staticClass:"el-icon-arrow-left"})]),_vm._v(" "),_c('ul',[_c('li',{staticClass:"fl border_color"},[_c('img',{attrs:{"src":require("@/assets/img/08.jpg"),"alt":""}})])]),_vm._v(" "),_c('div',{staticClass:"pic_zoom_list_right fr"},[_c('i',{staticClass:"el-icon-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wei"},[_c('span',[_vm._v("重量：")]),_vm._v(" "),_c('span',[_vm._v("0.15 kg/件")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stock"},[_c('span',[_vm._v("库存：")]),_vm._v(" "),_c('span',[_vm._v("8321件")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"num"},[_c('span',[_vm._v("数量：")]),_vm._v(" "),_c('div',{staticClass:"num_handle"},[_c('div',{staticClass:"goods_info_num_jian fl"},[_c('i',{staticClass:"el-icon-minus"})]),_vm._v(" "),_c('div',{staticClass:"goods_info_num_input fl"},[_c('input',{attrs:{"type":"text"}})]),_vm._v(" "),_c('div',{staticClass:"goods_info_num_jia fl"},[_c('i',{staticClass:"el-icon-plus"})]),_vm._v(" "),_c('div',{staticClass:"goods_info_num_stock fl"},[_vm._v("  件")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"storage"},[_c('span',[_vm._v("仓储：")]),_vm._v(" "),_c('div',{staticClass:"storage_handle"},[_c('div',{staticClass:"item red"},[_vm._v("\n              义乌仓储\n              "),_c('img',{attrs:{"src":require("@/assets/img/09.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buy_btn"},[_c('div',{staticClass:"btn fl"},[_vm._v("立即购买")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goods_info_content wrap"},[_c('div',{staticClass:"title"},[_vm._v("商品详情")]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('p',[_c('img',{attrs:{"src":require("@/assets/img/10.jpg"),"alt":""}}),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/img/11.jpg"),"alt":""}})])])])
}]

export { render, staticRenderFns }